import * as React from 'react';

import {
    StaticQuery, graphql 
} from 'gatsby';
import ReactMarkdown from 'react-markdown';

import { CustomHeader } from '../../components/shared/custom-header/custom-header.component';
import { isUndOrEmpty } from '../../utils/string-utils';
import * as styles from './index.module.css';

interface MentionsLegalesProps {
    content: string;
}

const mentionsLegalesPage: React.FC<MentionsLegalesProps> = ({ content }: MentionsLegalesProps) => {
    return (
        <>
            <CustomHeader transparent={false} dark={false} />
            <div className={styles.mentionsLegalesPage}>
                {!isUndOrEmpty(content) && <ReactMarkdown>{content}</ReactMarkdown>}
            </div>
        </>
    );
};

const MentionsLegalesEnhancer = (Component: React.ComponentType<MentionsLegalesProps>) => {
    return (
        <StaticQuery<{ strapiMentionsLegales: MentionsLegalesProps }>
            query={graphql`
				query MentionsLegalesQuery {
					strapiMentionsLegales {
						content
					}
				}
				`
            }
            render={data => <Component {...data.strapiMentionsLegales} />}
        />
    );
};

function MentionsLegalesPage() {
    return MentionsLegalesEnhancer(mentionsLegalesPage);
}

export default MentionsLegalesPage;

export { Head } from '../../components/head/head.component';